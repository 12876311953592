// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0a69b08191ae46fd9f5e2d73c83607c3@o99321.ingest.sentry.io/6013023',
    tracesSampleRate: 0.05,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost'],
      }),
    ],
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  })
}


